import {FloatingButton} from "../components/FloatingButton";
import SidebarLayout from "../components/SidebarLayout";
import {Outlet} from "react-router-dom";
import RightLayout from "../components/RightLayout";

export const HomeDeskTop = () => {
    return (
        <>
            <FloatingButton/>
            <div className="bg-white h-screen flex flex-row">
                <div className="basis-32">
                    <SidebarLayout/>
                </div>


                <div className="w-8/12 h-screen content-start basis-8/12 justify-center">
                    <Outlet/>
                </div>


                <div className="w-4/12  bg-white border-l border-grey-100  h-screen">
                    <RightLayout/>
                </div>
            </div>
        </>
    )
}