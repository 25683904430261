import SearchLayout from "./SearchLayout";
import {ProfileLayout} from "./posts/ProfileLayout";


interface PostProps {
    tag?: string,
}

function RightLayout(props:PostProps) {
    return (
        <div className="p-8 flex flex-col fixed  bottom-0 top-0 overflow-y-scroll w-1/4 no-scrollbar justify-items-center">
            <div className="space-x-7">
                <button
                    // onClick={() => setsignup(true)}
                    className="bg-gray-800 hover:bg-gray-900 duration-500 px-16 py-2 rounded-full text-white"
                >
                    Get started
                </button>
                <button
                    onClick={() => {
                        // setsignup(true);
                        // setsign(1);
                    }}
                    className="text-black hover:text-green-600"
                >
                    Sign in
                </button>
            </div>
            <SearchLayout/>
            <ProfileLayout/>
        </div>

    )
}

export default RightLayout;