import {BiUpArrow} from "react-icons/bi";
import {useEffect, useState} from "react";

export function FloatingButton() {
    const [up, setup] = useState(false);

    // hide on top at specific height
    useEffect(() => {
        window.onscroll = function () {
            console.error(window.scrollY)
            if (window.scrollY >= 444) {
                setup(true);
            } else {
                setup(false);
            }
        };
    });

    // scroll to top
    function scrolltotop() {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }

    return (
        <> {up && (
            <div
                onClick={scrolltotop}
                className="fixed hidden sm:block animate-bounce duration-500 z-40 hover:bg-green-700 bg-green-600 shadow-xl cursor-pointer rounded-md p-[13px] m-10 bottom-0 right-0"
            >
                <BiUpArrow className="w-4 h-4 text-white" />
            </div>
        )}</>
    )
}