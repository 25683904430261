import {FiSearch} from "react-icons/fi";

interface PostProps {
    tag?: string,
}

function SearchLayout(props:PostProps) {
    return (

            <div className="w-full py-10 relative">
                <input
                    onChange={(e) => {
                      //  setSearchTerm(e.target.value);
                    }}
                    className="px-12 w-full py-2 rounded-full border-gray-200 border-[1px] outline-none text-sm"
                    type="text"
                    placeholder="Search.."
                    name=""
                    id=""
                />
                <FiSearch className="absolute -translate-y-1/2 top-1/2  -translate-x-1/2 left-8 h-4" />
            </div>


        )
}

export default SearchLayout;